<template>
  <div class="container">
      <div @click="goback" style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px">
      <i class="el-icon-back"></i>
      返回
    </div>
    <div class="search" style="display:flex">
      <el-form ref="form" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <el-input v-model="search.cnsuo" placeholder="账号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="search.cnsuo" placeholder="昵称"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <!-- 列表 -->
    <el-table border v-bind:data="list">
      <el-table-column label="序号" type="index" width="50"></el-table-column>
      <el-table-column label="账号信息">
        <template v-slot="scope">
          <span>{{ scope.row.describe }}</span>
        </template>
      </el-table-column>
      <el-table-column label="用户昵称">
        <template v-slot="scope">
          <span>{{ scope.row.describe }}</span>
        </template>
      </el-table-column>
      <el-table-column label="公司统一信用码">
        <template v-slot="scope">
          <span>{{ scope.row.describe }}</span>
        </template>
      </el-table-column>
      <el-table-column label="审核状态">
        <template v-slot="scope">
          <span>{{ scope.row.describe }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="300px">
        <template v-slot="scope">
          <el-button type="info" size="small" @click="anthenticInfo(scope.row)">详情</el-button>
          <el-button type="primary" size="small" @click="anthenticPass(scope.row)">通过</el-button>
          <el-button type="danger" size="small" @click="denyAnthentic(scope.row)">拒绝</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      search: {
        cnsuo: '',
        cocn: '',
      },
      list: [
        {describe: '一个毛栗子'}
      ],
    }
  },
  computed: {},
  watch: {},
  methods: {
    anthenticInfo () {

    },
    anthenticPass () {
      this.$confirm('是否通过该用户的认证?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除请求
        this.$message({
          type: 'success',
          message: '通过成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消通过'
        });
      })
    },
    denyAnthentic(item) {
      this.$confirm('是否拒绝该用户的认证?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除请求
        this.$message({
          type: 'success',
          message: '拒绝成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消拒绝'
        });
      })
    },
    goback () {
        this.$router.push('/iotmanage/developer')
    }
  },
  filters: {},
  mounted () { },
  created () { }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
